import React, { useState } from 'react';
import {
    Stack,
    Tabs,
    Tab,
    Box,
    Button,
} from "@mui/material";
import SmsCategoryList from '../SmsCategoryList';
import CreditReport from '../reports/CreditReport';
import DebitReport from '../reports/DebitReport';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const OverView = () => {
    const [activeSubTab, setActiveSubTab] = useState(0);
    const navigate  = useNavigate();
  const location = useLocation();
    const handleSubTabChange = (event, newValue) => {
        setActiveSubTab(newValue);
    };

    const tab = location.state?.tab || "";
    const smsValue = location.state?.value || 0;
    const mode = location.state?.mode;
    

    
   
    const goBack = () => {
        const route = mode === "P" ? (tab === "" ? "/sms/list" : "/communication") 
                                   : (tab === "" ? "/sms/staff-list" : "/staff-com");
    
      
        
        navigate(route, { state: { value: smsValue ?? 0 } });
    };
    
    // Ensure goBack() is called and used correctly

    return (
        <Stack sx={{ padding: '8px 32px' }}>
            <Tabs
                value={activeSubTab}
                onChange={handleSubTabChange}
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label="SMS Category List" />
                <Tab label="SMS Credit Report" />
                <Tab label="SMS Debit Report" />
                <Box sx={{ marginLeft: 'auto' }}>
                    <Button
                         size="small"
                          variant="contained"
                          onClick={goBack}
     
                        >Back
                    </Button>
                </Box>
            </Tabs>

            <Box sx={{ marginTop: 2 }}>
                {activeSubTab === 0 && <SmsCategoryList />}
                {activeSubTab === 1 && <CreditReport />}
                {activeSubTab === 2 && <DebitReport />}
            </Box>
        </Stack>
    );
};

export default OverView;
import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";


const AlertSubscriptionList =SuspenseWrapper(lazy(()=>import('components/master/AlertSubscriptionList')));
const AlertSubscriptionForm =SuspenseWrapper(lazy(()=>import('components/master/AlertSubscriptionForm')));

const AlertSubscriptionRoutes = {
  path: "alert-subscription",
  element: <Outlet />,
  children: [
    {
      path: "list",
      index: true,
      element: <AlertSubscriptionList />,
    },
    {
        path: "edit/:hash",
        index: true,
        element: <AlertSubscriptionForm />,
      },
      {
        path: "add",
        index: true,
        element: <AlertSubscriptionForm />,
      },
]
  
};
export default AlertSubscriptionRoutes;
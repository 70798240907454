import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../ajax";
import DeleteConfirmation from "../common/DeleteConfirmation";
import GenGrid from "../common/GenGrid";
import ScreenHead from "../common/ScreenHead";
import SmsCategoryForm from "./SmsCategoryForm";
import ResponseSnackBars from "../common/ResponseSnackBars";
import SearchText from "../common/SearchText";
import { AddButton, DeleteButton, EditButton } from "../common/ActionButtons";

const api = new Api();

const columns = [
  {
    field: "cat_name",
    headerName: "SMS Category",
    editable: false,
    width: 260,
  },
  {
    field: "cat_code",
    headerName: "Category Code",
    editable: false,
    width: 180,
  },
];

const SmsCategoryList = () => {
  const [grid, setGrid] = useState({ count: 0, list: [] });
  const [pageState, changePageState] = useState({
    size: 25,
    index: 0,
    search: "",
    sort: [],
  });
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackDOpen, setSnackDOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [formState, setFormState] = useState(null);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    setLoading(true);
    api.post(
      "/sms/category/list",
      pageState,
      (res) => {
        setLoading(false);
        if (res.s) setGrid(res.d);
      },
      (err) => {
        setLoading(false);
      }
    );
    return () => { };
  }, [pageState]);

  const onPageChange = (pageIndex, details) => {
    changePageState({ ...pageState, index: pageIndex });
  };

  const onPageSizeChange = (pageSize, details) => {
    changePageState({ ...pageState, size: pageSize });
  };

  const onSortModelChange = (model, details) => {
    changePageState({ ...pageState, sort: model });
  };

  const onSearch = () => {
    changePageState({ ...pageState, search: searchText });
  };

  const onChangeSearchValue = (event) => {
    setSearchText(event.target.value);
  };

  const onFormClose = (reload) => {
    if (reload === true) {
      setSnackOpen(true);
      setFormState(null);
      changePageState({ ...pageState });
    }
    setFormOpen(false);
  };

  const openForm = () => {
    setFormOpen(true);
  };

  const openEditForm = () => {
    setEditLoading(true);
    api.post(
      "/sms/category/get-by-id",
      { sms_cat_id: selectedValues[0] },
      (res) => {
        setEditLoading(false);
        if (res.s) {
          setFormState(res.d);
          setFormOpen(true);
        }
      }
    );
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleSnackDClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackDOpen(false);
  };

  const askConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleDeleteConfirmed = () => {
    api.post("/sms/category/remove", { sms_cat_id: selectedValues }, (res) => {
      setOpenDeleteConfirmation(false);
      setSelectedValues([]);
      changePageState({ ...pageState });
      setSnackDOpen(true);
    });
  };

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false);
  };

  const onSearchEnterKey = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };
  const handleClear = () => {

    changePageState({ ...pageState, search: '' });
    setSearchText('')
  };

  return (
    <Stack sx={{ padding: { xs: "8px 4px", sm: "8px 32px" } }}>
      <ScreenHead
        title="SMS Category List"
        filterComponents={
          <>
            <SearchText
              onChange={onChangeSearchValue}
              onKeyUp={onSearchEnterKey}
              onSearch={onSearch}
              onClear={handleClear}
            />
          </>
        }
        actionComponents={
          <>
            {selectedValues.length === 1 && (
              <EditButton loading={editLoading} onClick={openEditForm} />
            )}
            {selectedValues.length >= 1 && (
              <DeleteButton onClick={askConfirmation} />
            )}
            {selectedValues.length === 0 && <AddButton onClick={openForm} />}
          </>
        }
      />
      <GenGrid
        rows={grid.list}
        loading={loading}
        columns={columns}
        rowCount={grid.count}
        pageSize={pageState.size}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        selectionModel={selectedValues}
        isRowSelectable={(params) => params.row.is_system !== "Y"}
        onSelectionModelChange={(ids) => {
          setSelectedValues(ids);
        }}
      />

      <SmsCategoryForm
        state={formState}
        open={formOpen}
        handleClose={onFormClose}
      />
      <ResponseSnackBars
        sOpen={snackOpen}
        rOpen={snackDOpen}
        onSClose={handleSnackClose}
        onRClose={handleSnackDClose}
      />
      <DeleteConfirmation
        open={openDeleteConfirmation}
        handleCancelDelete={handleCancelDelete}
        handleDeleteConfirmed={handleDeleteConfirmed}
      />
    </Stack>
  );
};

export default SmsCategoryList;

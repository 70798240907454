import { Divider, Stack, useMediaQuery, useTheme } from "@mui/material";

const ScreenHead = ({ title, actionComponents, filterComponents }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  if (actionComponents === undefined) {
    if (matches) {
      return (
        <Stack divider={<Divider />} sx={{ mb: "16px" }} spacing={1}>
          <h3 style={{ marginBottom: 0 }}>{title}</h3>
          {filterComponents}
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" alignItems="center" spacing={3}>
          <h3>{title}</h3>
          {filterComponents}
        </Stack>
      );
    }
  } else {
    if (matches) {
      return (
        <Stack divider={<Divider />} spacing={1} sx={{ mb: "8px" }}>
          <h3 style={{ marginBottom: 0 }}>{title}</h3>
          {filterComponents && <Stack>{filterComponents}</Stack>}
          <Stack direction="row" justifyContent="end" spacing={1}>
            {actionComponents}
          </Stack>
        </Stack>
      );
    } else {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={3}>
            <h3>{title}</h3>
            {filterComponents}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            {actionComponents}
          </Stack>
        </Stack>
      );
    }
  }
};

export default ScreenHead;

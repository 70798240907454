import CustomConfirmation from "./CustomConfirmation";

const DeleteConfirmation = ({
  open,
  handleDeleteConfirmed,
  handleCancelDelete,
}) => {
  return (
    <CustomConfirmation
      open={open}
      title="Delete Confirmation"
      content="Are you sure you want to delete?"
      handleCancel={handleCancelDelete}
      handleConfirmed={handleDeleteConfirmed}
    />
  );
};

export default DeleteConfirmation;

import { IconButton, InputAdornment, TextField, FormControl } from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear';

const SearchText = ({
  sSearchText,
  onChange,
  onKeyUp,
  onSearch,
  fullWidth,
  onClear
}) => {
  const [lSearchText, setlSearchText] = useState(
    sSearchText !== "" ? sSearchText : ""
  );

  useEffect(() => {
    setlSearchText(sSearchText);
  }, [lSearchText]);

  const handleClear = () =>{
    setlSearchText('')
    if (onClear) onClear();
  }

  return (
    // <FormControl className="main-input-box" fullWidth>
    <TextField
      label="Search"
      color="primary"
      size="small"
      className="inner-input-box post-input-box"
      autoComplete="off"
      onChange={(e) => {
        onChange(e);
      }}
      onKeyUp={onKeyUp}
      value={lSearchText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="search" onClick={onSearch} edge="end">
              <SearchIcon />
            </IconButton>
            <IconButton aria-label="search" onClick={handleClear} edge="end">
              <ClearIcon/>
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth={fullWidth || false}
    />
    // </FormControl>
  );
};

export default SearchText;

import { DialogTitle, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
const DialogFormTitle = ({ mode, children }) => {
  return (
    <DialogTitle>
      <Stack direction="row" alignItems="center">
        {mode === "A" && <AddIcon sx={{ height: 24, width: 24, mr: 1 }} />}
        {mode === "E" && <EditIcon sx={{ height: 24, width: 24, mr: 1 }} />}
        {mode === "L" && <LinkIcon sx={{ height: 24, width: 24, mr: 1 }} />}
        {mode === "R" && <KeyboardReturnRoundedIcon sx={{ height: 24, width: 24, mr: 1 }} />}
        <>{children}</>
      </Stack>
    </DialogTitle>
  );
};

export default DialogFormTitle;

import { DataGrid } from "@mui/x-data-grid";

const GenGrid = (props) => {
  return (
    <div
      style={{
        height: props.height ? props.height : "calc(100vh - 192px)",
        width: "100%",
        backgroundColor: "#FFF",
        borderRadius: "4px",
      }}
    >
      <DataGrid
        disableColumnMenu={true}
        rowsPerPageOptions={[25, 50,100]}
        paginationMode="server"
        sortingMode="server"
        showColumnRightBorder={true}
        rowHeight={44}
        headerHeight={44}
        checkboxSelection
        disableSelectionOnClick
        {...props}
      />
    </div>
  );
};

export default GenGrid;

import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const HallticketForm = SuspenseWrapper(
  lazy(() => import("components/certificate/HallticketForm"))
);
const HallticketList = SuspenseWrapper(
  lazy(() => import("components/certificate/HallticketList"))
);
const TransForm = SuspenseWrapper(
  lazy(() => import("components/certificate/TransForm"))
);
const TransList = SuspenseWrapper(
  lazy(() => import("components/certificate/TransList"))
);
const CertMasterForm = SuspenseWrapper(
  lazy(() => import("components/certificate/CertMasterForm"))
);
const CertMasterList = SuspenseWrapper(
  lazy(() => import("components/certificate/CertMasterList"))
);
const StaffTransForm = SuspenseWrapper(
  lazy(() => import("components/certificate/StaffTransForm"))
);
const StaffTransList = SuspenseWrapper(
  lazy(() => import("components/certificate/StaffTransList"))
);
const TcForm = SuspenseWrapper(lazy(() => import("components/certificate/TcForm")));
const TcList = SuspenseWrapper(lazy(() => import("components/certificate/TcList")));

const IdcardList = SuspenseWrapper(
  lazy(() => import("components/certificate/IdcardList"))
);

const IdcardForm = SuspenseWrapper(
  lazy(() => import("components/certificate/IdcardForm"))
);
const IdcardTemplateList = SuspenseWrapper(
  lazy(() => import("components/certificate/IdcardTemplateList"))
);
const IdCardTemplateForm = SuspenseWrapper(
  lazy(() => import("components/certificate/IdCardTemplateForm"))
);
const CertRoutes = {
  path: "cert",
  element: <Outlet />,
  children: [
    {
      path: "hallticket",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <HallticketList />,
        },
        {
          path: "add",
          element: <HallticketForm />,
        },
        {
          path: "edit/:hash",
          element: <HallticketForm />,
        },
      ],
    },
    {
      path: "master",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <CertMasterList />,
        },
        {
          path: "add",
          element: <CertMasterForm />,
        },
        {
          path: "edit/:hash",
          element: <CertMasterForm />,
        },
      ],
    },
    {
      path: "bonafide_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="bonafide_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="bonafide_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="bonafide_cert" />,
        },
      ],
    },
    {
      path: "it_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="it_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="it_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="it_cert" />,
        },
      ],
    },
    {
      path: "conduct_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="conduct_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="conduct_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="conduct_cert" />,
        },
      ],
    },
    {
      path: "attendance_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="attendance_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="attendance_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="attendance_cert" />,
        },
      ],
    },
    {
      path: "participant_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="participant_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="participant_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="participant_cert" />,
        },
      ],
    },
    {
      path: "club_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="club_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="club_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="club_cert" />,
        },
      ],
    },
    {
      path: "admission_abstract_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="admission_abstract_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="admission_abstract_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="admission_abstract_cert" />,
        },
      ],
    },
    {
      path: "tc_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TcList />,
        },
        {
          path: "add",
          element: <TcForm />,
        },
        {
          path: "edit/:hash",
          element: <TcForm />,
        },
      ],
    },
    {
      path: "staff_service_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffTransList pageMode="staff_service_cert" />,
        },
        {
          path: "add",
          element: <StaffTransForm pageMode="staff_service_cert" />,
        },
        {
          path: "edit/:hash",
          element: <StaffTransForm pageMode="staff_service_cert" />,
        },
      ],
    },
    {
      path: "staff_salary_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffTransList pageMode="staff_salary_cert" />,
        },
        {
          path: "add",
          element: <StaffTransForm pageMode="staff_salary_cert" />,
        },
        {
          path: "edit/:hash",
          element: <StaffTransForm pageMode="staff_salary_cert" />,
        },
      ],
    },
    {
      path: "completion_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="completion_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="completion_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="completion_cert" />,
        },
      ],
    },
    {
      path: "sports_cert",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransList pageMode="sports_cert" />,
        },
        {
          path: "add",
          element: <TransForm pageMode="sports_cert" />,
        },
        {
          path: "edit/:hash",
          element: <TransForm pageMode="sports_cert" />,
        },
      ],
    },
    {
      path: "idcard-template",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <IdcardTemplateList />,
        },
        {
          path: "add",
          element: <IdCardTemplateForm />,
        },
        {
          path: "edit/:hash",
          element: <IdCardTemplateForm />,
        },
      ],
    },
    {
      path: "idcard",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <IdcardList />,
        },
        {
          path: "add",
          element: <IdcardForm />,
        },
        {
          path: "edit/:hash",
          element: <IdcardForm />,
        },
      ],
    },
  ],
};

export default CertRoutes;

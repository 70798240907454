import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import './../custom.css';

const DialogFormActions = ({ onCancel, saveLoading, onSave, disableSave }) => {
  return (
    <DialogActions style={{ padding: "8px 16px 16px 16px" }}>
      <Button
        //disabled={disableSave || false}
        color="error"
        size="small"
        disableElevation
        variant="contained"
        className="sm-theme-box-shadow"
        onClick={onCancel}
        tabIndex={-2}
      >
        Cancel
      </Button>
      <LoadingButton
        color="primary"
        disabled={disableSave || false}
        size="small"
        loading={saveLoading}
        loadingPosition="center"
        disableElevation
        variant="contained"
        className="sm-theme-box-shadow"
        onClick={onSave}
      >
        Save
      </LoadingButton>
    </DialogActions>
  );
};

export default DialogFormActions;

import { createSlice } from "@reduxjs/toolkit";

const initialState = { value: {} };

export const ExclusiveMenuList = createSlice({
  name: "ExclusiveMenuList",
  initialState,
  reducers: {
    setAllExclusiveMenuList: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setAllExclusiveMenuList } = ExclusiveMenuList.actions;
export default ExclusiveMenuList.reducer;

import { Alert, Collapse } from "@mui/material";

const ErrorAlert = ({ state }) => {
  return (
    <Collapse in={state.showError && state.msg !== ""}>
      <Alert sx={{ overflow: "auto" }} severity="error">
        {state.msg}
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;

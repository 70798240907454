import { LoadingButton } from '@mui/lab'
import { Button, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import '../custom.css'

const AddButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      color='primary'
      disableElevation
      size='small'
      className='sm-theme-box-shadow'
      onClick={onClick}
    >
      Add
    </Button>
  )
}

const EditButton = ({ loading, onClick }) => {
  return (
    <LoadingButton
      loading={loading}
      variant='contained'
      color='primary'
      disableElevation
      size='small'
      onClick={onClick}
    >
      Edit
    </LoadingButton>
  )
}

const DeleteButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      disableElevation
      size='small'
      color='error'
      onClick={onClick}
    >
      Delete
    </Button>
  )
}

const BulkMappingButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      disableElevation
      size='small'
      color='success'
      onClick={onClick}
      className='sm-theme-box-shadow'
    >
      Bulk Mapping
    </Button>
  )
}

const SubmitButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      disableElevation
      size='small'
      color='success'
      onClick={onClick}
    >
      Submit
    </Button>
  )
}

const CommonButton = ({ onClick, color, label }) => {
  return (
    <Button
      variant='contained'
      disableElevation
      size='small'
      className='sm-theme-box-shadow'
      color={color === null ? 'success' : color}
      onClick={onClick}
      accesskey="x"
      title="Cancel (Alt + X)"
    >
      {label === null ? 'No Label' : label}
    </Button>
  )
}

const CommonLoadingButton = ({ loading, onClick, color, label, accessKey, tooltip }) => {
  return (
    <LoadingButton
      loading={loading}
      variant='contained'
      disableElevation
      size='small'
      color={color === null ? 'success' : color}
      className='sm-theme-box-shadow'
      onClick={onClick}
      accessKey={accessKey}
      title={tooltip}
    >
      {label === null ? 'No Label' : label}
    </LoadingButton>
  )
}
const OnNext = ({ onClick, isVisible }) => {
  return (
    <IconButton
      color="primary"
      size="small"
      onClick={onClick}
      disabled={!isVisible}
    //style={{ display: isVisible ? 'inline-flex' : 'none' }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};
const OnPrevious = ({ onClick, isVisible }) => {
  return (
    <IconButton
      color='primary'
      size='small'
      onClick={onClick}
      disabled={!isVisible}
    //style={{ display: isVisible ? 'inline-flex' : 'none' }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
}

const ImportButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      color='primary'
      disableElevation
      size='small'
      onClick={onClick}
    >
      Import
    </Button>
  )
}

const UnblockButton = ({ onClick }) => {
  return (
    <Button
      variant='contained'
      disableElevation
      size='small'
      color='success'
      onClick={onClick}
    >
      Unblock
    </Button>
  )
}

export {
  AddButton,
  EditButton,
  DeleteButton,
  BulkMappingButton,
  SubmitButton,
  CommonLoadingButton,
  CommonButton,
  OnNext,
  OnPrevious,
  ImportButton,
  UnblockButton,
}

import React, {  lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StoreRoom = SuspenseWrapper(lazy(()=> import("components/inventory/StoreRoom")));
const SupplierList = SuspenseWrapper(lazy(()=> import("components/inventory/SupplierList")));
const ShopsList = SuspenseWrapper(lazy(()=> import("components/inventory/ShopsList")));
const ItemGroups = SuspenseWrapper(lazy(()=> import("components/inventory/ItemGroups")));
const ItemsCategoryList = SuspenseWrapper(lazy(()=> import("components/inventory/ItemCatList")));
const ItemList = SuspenseWrapper(lazy(()=> import("components/inventory/ItemList")));
const ClassBDList = SuspenseWrapper(lazy(()=> import("components/inventory/ClassBDList")));
const ClassBDForm = SuspenseWrapper(lazy(()=> import("components/inventory/ClassBDForm")));
const ScholarBDForm = SuspenseWrapper(lazy(()=> import("components/inventory/ScholarBDForm")));
const ScholarBDList = SuspenseWrapper(lazy(()=> import("components/inventory/ScholarBDList")));
const ScholarBDView = SuspenseWrapper(lazy(()=> import("components/inventory/ScholarBDView")));
const ClassBDView = SuspenseWrapper(lazy(()=> import("components/inventory/ClassBDView")));
const ClassBDStudentView = SuspenseWrapper(lazy(()=> import("components/inventory/ClassBDStudentView")));
const PurchaseList = SuspenseWrapper(lazy(()=> import("components/inventory/PurchaseList")));
const PurchaseForm = SuspenseWrapper(lazy(()=> import("components/inventory/PurchaseForm")));
const StockDisposalList = SuspenseWrapper(lazy(()=> import("components/inventory/StockDisposalList")));
const StockDisposalForm = SuspenseWrapper(lazy(()=> import("components/inventory/StockDisposalForm")));
const StockConsumptionList = SuspenseWrapper(lazy(()=> import("components/inventory/StockConsumptionList")));
const StockConsumptionForm = SuspenseWrapper(lazy(()=> import("components/inventory/StockConsumptionForm")));
const TransferList = SuspenseWrapper(lazy(()=> import("components/inventory/TransferList")));
const TransferForm = SuspenseWrapper(lazy(()=> import("components/inventory/TransferForm")));
const MasterList = SuspenseWrapper(lazy(()=> import("components/inventory/MasterList")));
const PurchaseTabComponent = SuspenseWrapper(lazy(()=> import("components/inventory/PurchaseTabComponent")));
const DisposalTabComponent = SuspenseWrapper(lazy(()=> import("components/inventory/DisposalTabComponent")));
const ConsumptionTabComponent = SuspenseWrapper(lazy(()=> import("components/inventory/ConsumptionTabComponent")));
const TransferTabComponent = SuspenseWrapper(lazy(()=> import("components/inventory/TransferTabComponent")));
const ItemMaster = SuspenseWrapper(lazy(()=> import("components/inventory/ItemMaster")));
const PurchaseReturnStepper = SuspenseWrapper(lazy(()=> import("components/inventory/PurchaseReturnStepper")));
const PurchaseReturnSelection = SuspenseWrapper(lazy(()=> import("components/inventory/PurchaseReturnSelection")));
const InventoryRoutes = {
    path: "inventory",
    element: <Outlet />,
    children: [
        {
            path: "master",
            element: <MasterList />,
        },
        {
            path: "storeroom",
            element: <StoreRoom />,
        },
        {
            path: "supplier",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <SupplierList />,
                },
            ],
        },
        {
            path: "shops",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <ShopsList />,
                },
            ],
        },
        {
            path: "item-groups",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ItemGroups />,
                },
            ],
        },
        {
            path: "items-category",
            element: <Outlet />,
            children: [
                {
                    path: "list",
                    element: <ItemsCategoryList />,
                },
            ],
        },
        {
            path: "item",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <ItemMaster />,
                },
            ],
        },
        {
            path: "bd-class",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <ClassBDList />,
                }, {
                    path: "new",
                    element: <ClassBDForm />,
                }, {
                    path: "edit/:hash",
                    element: <ClassBDForm />,
                },{
                    path: "view",
                    element: <ClassBDView />,
                },{
                    path: "view-list",
                    element: <ClassBDStudentView />,
                },
            ],
        },
        {
            path: "bd-scholar",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <ScholarBDList />,
                }, {
                    path: "new",
                    element: <ScholarBDForm />,
                }, {
                    path: "edit/:hash",
                    element: <ScholarBDForm />,
                },
            ],
        },
        {
            path: "bd-purchase",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <PurchaseTabComponent />,
                }, {
                    path: "new",
                    element: <PurchaseForm />,
                }, {
                    path: "edit/:hash",
                    element: <PurchaseForm />,
                },{
                    path: "return/:hash",
                    element: <PurchaseReturnStepper />,
                },
            ],
        },
        {
            path: "stock-disposal",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <DisposalTabComponent />,
                }, {
                    path: "new",
                    element: <StockDisposalForm />,
                }, {
                    path: "edit/:hash",
                    element: <StockDisposalForm />,
                },
            ],
        },
        {
            path: "stock-consumption",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <ConsumptionTabComponent />,
                }, {
                    path: "new",
                    element: <StockConsumptionForm />,
                }, {
                    path: "edit/:hash",
                    element: <StockConsumptionForm />,
                },
            ],
        },
        {
            path: "transfer",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    element: <TransferTabComponent />,
                },
                 {
                    path: "new",
                    element: <TransferForm />,
                }, 
                {
                    path: "edit/:hash",
                    element: <TransferForm />,
                },
            ],
        },
       
    ],
};

export default InventoryRoutes;
import { Dialog, DialogContent, Stack, TextField, FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../ajax";
import DialogFormTitle from "../common/DialogFormTitle";
import DialogFormActions from "../common/DialogFormActions";
import ErrorAlert from "../common/ErrorAlert";
import '../custom.css'

const api = new Api();

const SmsCategoryForm = ({ handleClose, open, state }) => {
  const [formValues, setFormValues] = useState({
    cat_name: "",
    cat_code: "",
    sms_cat_id: 0,
  });
  const [submited, setSubmited] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [formMode, setFormMode] = useState("A");

  const [userErrState, setUserErrState] = useState({
    showError: false,
    msg: "",
  });

  useEffect(() => {
    if (state !== null) {
      setFormMode("E");
      setFormValues({ ...state });
    } else {
      setFormMode("A");
    }
  }, [state]);

  const onChangeFormValue = (event) => {
    if (event.target.name !== "") {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }

    if (userErrState.showError) setUserErrState({ showError: false, msg: "" });
  };

  const clearAndClose = (reload) => {
    setUserErrState({ showError: false, msg: "" });
    setFormValues({ cat_name: "", sms_cat_id: 0, cat_code: "" });
    setFormMode("A");
    setSubmited(false);
    setSaveLoading(false);
    handleClose(reload);
  };

  const saveForm = () => {
    let saveAPI = "/sms/category/create";
    if (formMode === "E") {
      saveAPI = "/sms/category/update";
    }
    setUserErrState({ showError: false, msg: "" });
    if (formValues.cat_name === "" || formValues.cat_code === "") {
      setUserErrState({ showError: true, msg: "Category name and code are required." });
      setSubmited(true);
      return;
    }
      if (formValues.cat_code.length !== 1) {
      setUserErrState({ showError: true, msg: "Category code must be exactly one character." });
      setSubmited(true);
      return;
    }
    setSubmited(false);
    setSaveLoading(true);
    api.post(
      saveAPI,
      formValues,
      (res) => {
        setSaveLoading(false);
        if (!res.s) {
          setUserErrState({ showError: true, msg: res.m });
        } else {
          clearAndClose(true);
        }
      },
      () => {
        setSaveLoading(false);
      }
    );
  };
  return (
    <Dialog open={open}>
      <DialogFormTitle mode={formMode}>SMS Category</DialogFormTitle>
      <DialogContent>
        <Stack sx={{ mt: 1, minWidth: 250 }} spacing={2}>
          <FormControl className="main-input-box" fullWidth>
            <TextField
              error={submited && formValues.cat_name === ""}
              name="cat_name"
              label="SMS Category"
              required
              autoFocus
              size="small"
              className="inner-input-box"
              onChange={onChangeFormValue}
              value={formValues.cat_name}
              placeholder="Enter the SMS Category"
              fullWidth
            />
          </FormControl>
          <FormControl className="main-input-box" fullWidth>
            <TextField
              error={
                submited &&
                (formValues.cat_code === "" || formValues.cat_code.length > 3)
              }
              name="cat_code"
              label="Category Code"
              required
              size="small"
              onChange={onChangeFormValue}
              value={formValues.cat_code}
              placeholder="Enter the SMS Category Code"
              fullWidth
              className="inner-input-box"
            />
          </FormControl>
          <ErrorAlert state={userErrState} />
        </Stack>
      </DialogContent>
      <DialogFormActions
        onCancel={clearAndClose}
        onSave={saveForm}
        saveLoading={saveLoading}
      />
    </Dialog>
  );
};

export default SmsCategoryForm;

import { Alert, Snackbar } from "@mui/material";

const ResponseSnackBars = ({ sOpen, onSClose, rOpen, onRClose,fopen,onFClose,fSnackContent,iopen,onIClose,iSnackContent}) => {
  return (
    <>
      <Snackbar open={sOpen} autoHideDuration={6000} onClose={onSClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={onSClose} severity="success" sx={{ width: "100%" }}>
          Data saved successfully
        </Alert>
      </Snackbar>
      <Snackbar open={rOpen} autoHideDuration={6000} onClose={onRClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={onRClose} severity="error" sx={{ width: "100%" }}>
          Data removed successfully
        </Alert>
      </Snackbar>
         <Snackbar open={fopen} autoHideDuration={6000} onClose={onFClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
         <Alert onClose={onFClose} severity="warning" sx={{ width: "100%" }}>
           {fSnackContent}
         </Alert>
       </Snackbar>
       <Snackbar open={iopen} autoHideDuration={6000} onClose={onIClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
         <Alert onClose={onIClose} severity="success" sx={{ width: "100%" }}>
           {iSnackContent}
         </Alert>
       </Snackbar>
    </>
  );
};

export default ResponseSnackBars;

import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TransportCategoryList = SuspenseWrapper(
  lazy(() => import("components/transport/CategoryList"))
);
const TransportCompanyList = SuspenseWrapper(
  lazy(() => import("components/transport/CompanyList"))
);
const TransportBrandList = SuspenseWrapper(
  lazy(() => import("components/transport/BrandList"))
);
const TransportVehicleList = SuspenseWrapper(
  lazy(() => import("components/transport/VehicleList"))
);
const TransportVehicleForm = SuspenseWrapper(
  lazy(() => import("components/transport/VehicleForm"))
);
const TransportDocumentTypeList = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentTypeList"))
);
const TransportDocumentList = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentList"))
);
const TransportDocumentForm = SuspenseWrapper(
  lazy(() => import("components/transport/DocumentForm"))
);
const TransportStageList = SuspenseWrapper(
  lazy(() => import("components/transport/StageList"))
);
const TransportStopList = SuspenseWrapper(
  lazy(() => import("components/transport/StopList"))
);
const TransportRouteList = SuspenseWrapper(
  lazy(() => import("components/transport/RouteList"))
);
const TransportRouteStopList = SuspenseWrapper(
  lazy(() => import("components/transport/RouteStopList"))
);
const TransportScholarList = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarList"))
);
const TransportScholarForm = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarForm"))
);
const TransportScholarExit = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarExitForm"))
);
const TransportScholarRevert = SuspenseWrapper(
  lazy(() => import("components/transport/ScholarRevertForm"))
);
const TransAtt = SuspenseWrapper(
  lazy(() => import("components/transport/TransAtt"))
)
const DriverList = SuspenseWrapper(
  lazy(() => import("components/transport/Driverlist"))
)

const Tripcardview = SuspenseWrapper(
  lazy(() => import("components/transport/Tripcardview"))
);
const VehicleMap = SuspenseWrapper(
  lazy(() => import("components/transport/VehicleMap"))
);
const Tripdetails = SuspenseWrapper(
  lazy(() => import("components/transport/Tripsdetails"))
);
const TransportDashboard = SuspenseWrapper(
  lazy(() => import("components/transport/Dashboard"))
);
const Routes = SuspenseWrapper(
  lazy(() => import("components/transport/Routes"))
);

const Map = SuspenseWrapper(
  lazy(() => import("components/transport/Map"))
);

const AttendanceList = SuspenseWrapper(
  lazy(() => import("components/transport/AttenList"))
);

const TransportRoutes = {
  path: "transport",
  element: <Outlet />,
  children: [
    {
      path: "category-list",
      element: <TransportCategoryList />,
    },
    {
      path: "company-list",
      element: <TransportCompanyList />,
    },
    {
      path: "brand-list",
      element: <TransportBrandList />,
    },
    {
      path: "dashboard",
      element: <TransportDashboard />,
    },
    {
      path: "vehicle",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportVehicleList />,
        },
        {
          path: "add",
          element: <TransportVehicleForm />,
        },
        {
          path: "map",
          element: <VehicleMap />,
        },
        {
          path: "map/v2",
          element: <Map />,
        },
        {
          path: "tripdetail",
          element: <Tripdetails />,
        },
        {
          path: "edit/:hash",
          element: <TransportVehicleForm />,
        },
        {
          path: "routes",
          element: <Routes />,
        },
      ],
    },
    {
      path: "doctype-list",
      element: <TransportDocumentTypeList />,
    },
    {
      path: "document-list",
      element: <TransportDocumentList />,
    },
    {
      path: "document",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportDocumentList />,
        },
        {
          path: "add",
          element: <TransportDocumentForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportDocumentForm />,
        },
      ],
    },
    {
      path: "stage-list",
      element: <TransportStageList />,
    },
    {
      path: "stop-list",
      element: <TransportStopList />,
    },
    {
      path: "route-list",
      element: <TransportRouteList />,
    },
    {
      path: "routestop-list",
      element: <TransportRouteStopList />,
    },
    {
      path: "scholar",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TransportScholarList />,
        },
        {
          path: "transport-attendance",
          element: <AttendanceList />,
        },
        {
          path: "add",
          element: <TransportScholarForm />,
        },
        {
          path: "edit/:hash",
          element: <TransportScholarForm />,
        },
        {
          path: "view/:hash",
          element: <TransportScholarExit />,
        },
        {
          path: "revert/:hash",
          element: <TransportScholarRevert />,
        },
      ],
    },
    {
      path: "Attendance",
      element: <Outlet />,
      children: [
        {
          path: "att",
          element: <TransAtt />,
        },
      ],
    },
    {
      path: "Driver",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <DriverList />,
        },
      ],
    },
    {
      path: "trip",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <Tripcardview />,
        },
      ],
    },
  ],
};

export default TransportRoutes;

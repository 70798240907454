import { configureStore } from "@reduxjs/toolkit";
import instituteConfigReducer from "../features/instituteConfig";
import currentUserReducer from "../features/currentUser";
import roleActionDisableReducer from "../features/roleActionDisable";
import dashboardAccessReducer from "../features/dashboardAccess";
import IsMobileNoMaskedReducer from "../features/IsMobileNoMasked";
import reportOrderSliceReducer from "features/reportOrder";
import instExclusiveMenuListReducer from "../features/instExclusiveMenuList";
import ExclusivemenuReducer from "../features/ExclusiveMenu";
import menuReducer from "../features/menuSlice";
import languageReducer from "../features/languageReducer";

export const store = configureStore({
  reducer: {
    instituteConfig: instituteConfigReducer,
    currentUser: currentUserReducer,
    roleActionDisable: roleActionDisableReducer,
    dashboardAccess: dashboardAccessReducer,
    IsMobileNoMasked: IsMobileNoMaskedReducer,
    reportOrderSlice: reportOrderSliceReducer,
    instExclusiveMenuList: instExclusiveMenuListReducer,
    ExclusiveMenuList: ExclusivemenuReducer,
    menu: menuReducer,
    language: languageReducer,
  },
});

import React, {  lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const QuestionList = SuspenseWrapper(lazy(()=> import("components/question-bank/QuestionList")));
const QuestionForm = SuspenseWrapper(lazy(()=> import("components/question-bank/QuestionForm")));

const QuestionBankRoutes = {
    path: "question-bank",
    element: <Outlet />,
    children: [
        {
            path: "questions",
            element: <QuestionList />,
        },
        {
            path: "add",
            element: <QuestionForm />,
        },
        {
            path: "edit/:hash",
            element: <QuestionForm />,
          },
     
    ],
};

export default QuestionBankRoutes;
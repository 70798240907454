import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";



const ClassiSchemeList = SuspenseWrapper(lazy(() => import("components/library/ClassiSchemeList")));
const ClassiCodeList = SuspenseWrapper(lazy(() => import("components/library/ClassiCodeList")));
const LibList = SuspenseWrapper(lazy(() => import("components/library/LibList")));
const LibraryBookList = SuspenseWrapper(lazy(() => import("components/library/LibraryBookList")));
const LibraryBookCopy = SuspenseWrapper(lazy(() => import("components/library/LibraryBookCopy")));
const ClassiLocMapList = SuspenseWrapper(lazy(() => import("components/library/ClassiLocMapList")));
const Dashboard = SuspenseWrapper(lazy(() => import("components/library/Dashboard")));
const InwardList = SuspenseWrapper(lazy(() => import("components/library/InwardList")));
// const InwardForm = SuspenseWrapper(lazy(() => import("components/library/InwardForm")));
const OutwardList=SuspenseWrapper(lazy(() => import("components/library/OutwardList")));
const BorrowBookList=SuspenseWrapper(lazy(() => import("components/library/BorrowBookList")));
const BorrowBookForm=SuspenseWrapper(lazy(() => import("components/library/BorrowBookForm")));
const DocumentView=SuspenseWrapper(lazy(() => import("components/library/DocumentView")));
const LibBookImportStatusList = SuspenseWrapper(lazy(() => import("components/library/LibBookImportStatusList")));
const LibBookImportForm = SuspenseWrapper(lazy(() => import("components/library/LibBookImportForm")));
const LibBookImportList = SuspenseWrapper(lazy(() => import("components/library/LibBookImportList")));
const LibBookTypeList = SuspenseWrapper(lazy(() => import("components/library/LibBookTypeList")));
const LibBookTypeForm = SuspenseWrapper(lazy(() => import("components/library/LibBookTypeForm")));
const LibBookCategoryList = SuspenseWrapper(lazy(() => import("components/library/LibBookCategoryList")));
const LibBookCategoryForm = SuspenseWrapper(lazy(() => import("components/library/LibBookCategoryForm")));
const BookLanguageList = SuspenseWrapper(lazy(() => import("components/library/BookLanguageList")));
const BookLanguageForm = SuspenseWrapper(lazy(() => import("components/library/BookLanguageForm")));
const LibDepartmentList = SuspenseWrapper(lazy(() => import("components/library/LibDepartmentList")));
const LibDepartmentForm = SuspenseWrapper(lazy(() => import("components/library/LibDepartmentForm")));
const LibrarySubjectList = SuspenseWrapper(lazy(() => import("components/library/LibrarySubjectList")));
const LibrarySubjectForm = SuspenseWrapper(lazy(() => import("components/library/LibrarySubjectForm")));
const PeriodList = SuspenseWrapper(lazy(() => import("components/library/PeriodList")));
const PeriodForm = SuspenseWrapper(lazy(() => import("components/library/PeriodForm")));
const OutwardTypeList = SuspenseWrapper(lazy(() => import("components/library/OutwardTypeList")));
const LibrarySettings =  SuspenseWrapper(lazy(() => import("components/library/LibrarySettings")));
const InwardStep = SuspenseWrapper(lazy(() => import("components/library/InwardStep")));
const LibBookTagList = SuspenseWrapper(lazy(() => import("components/library/LibBookTag")));
const LibBookTagForm = SuspenseWrapper(lazy(() => import("components/library/LibBookTagForm")));
const LibraryFineSlabList = SuspenseWrapper(lazy(() => import("components/library/LibraryFineSlab")));

const LibraryRoutes = {
  path: "library",
  element: <Outlet />,
  children: [{
    path: "dashboard",
    element:<Outlet />,
    children: [
      {
        path: "",
        index: true,
        element: <Dashboard />,
      },
    ]
  },{
      path: "classi-scheme",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <ClassiSchemeList />,
        },
      ]
    },
    {
      path: "classi-code",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <ClassiCodeList />,
        },
      ]
    },
    {
      path: "lib-master",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibList />,
        },
      ]
    },
    {
      path: "lib-book",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibraryBookList />,
        },
      ]
    },
    {
      path: "lib-period",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <PeriodList />,
        },
        {
          path: "add",
          index: true,
          element: <PeriodForm />,
        },
      ]
    },
    {
      path: "loc-map",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <ClassiLocMapList />,
        },
      ]
    },{
      path: "inward",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <InwardList />,
        },
        {
          path: "add",
          index: true,
          element: <InwardStep />,
        },
        {
          path: "edit/:hash",
          index: true,
          element: <InwardStep />,
        },
        {
          path: "book-list/:hash",
          index: true,
          element: <DocumentView />,
        },
      ]
    },{
      path: "outward",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <OutwardList />,
        },
        {
          path: "outward-type",
          index: true,
          element: <OutwardTypeList />,
        },
      ]
    },
    {
      path: "borrow",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <BorrowBookList />,
        },
        {
          path: "add",
          index: true,
          element: <BorrowBookForm />,
        },
      ]
    },
    {
      path: "import-list",
      element: <LibBookImportList />,
    },
    {
      path: "import",
      element: <LibBookImportForm />,
    },
    {
      path: "import-status/:hash",
      element: <LibBookImportStatusList />,
    },
    {
      path: "book-type",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibBookTypeList />,
        },
        {
          path: "add",
          index: true,
          element: <LibBookTypeForm />,
        },
      ]
    },
    {
      path: "book-category",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibBookCategoryList />,
        },
        {
          path: "add",
          index: true,
          element: <LibBookCategoryForm />,
        },
      ]
    },
    {
      path: "book-language",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <BookLanguageList />,
        },
        {
          path: "add",
          index: true,
          element: <BookLanguageForm />,
        },
      ]
    },
    {
      path: "lib-department",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibDepartmentList />,
        },
        {
          path: "add",
          index: true,
          element: <LibDepartmentForm />,
        },
      ]
    },
    {
      path: "lib-subject",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibrarySubjectList />,
        },
        {
          path: "add",
          index: true,
          element: <LibrarySubjectForm />,
        },
      ]
    },
    {
      path: "lib-setting",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibrarySettings />,
        },
      ]
    },
    {
      path: "book-tag",
      element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibBookTagList />,
        },
        {
          path: "add",
          index: true,
          element: <LibBookTagForm />,
        },
      ]
    },
    {
      path: "fine-slab",
    element:<Outlet />,
      children: [
        {
          path: "",
          index: true,
          element: <LibraryFineSlabList />,
        }
  ],
}
  ]

};

export default LibraryRoutes;

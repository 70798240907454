import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import GenDialog from "./GenDialog";
import './../custom.css'

const CustomConfirmation = ({
  title,
  content,
  open,
  handleConfirmed,
  handleCancel,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    setSaveLoading(false);
  }, [open]);

  const confirmed = () => {
    setSaveLoading(true);
    handleConfirmed && handleConfirmed();
  };

  const cancel = () => {
    setSaveLoading(false);
    handleCancel && handleCancel();
  };

  return (
    <GenDialog
      open={open}
      title={title}
      content={content}
      actions={
        <>
          <Button
            size="small"
            disableElevation
            className="sm-theme-box-shadow"
            disabled={saveLoading}
            variant="contained"
            onClick={cancel}
          >
            No
          </Button>
          <LoadingButton
            size="small"
            color="error"
            className="sm-theme-box-shadow"
            loading={saveLoading}
            loadingPosition="center"
            disableElevation
            variant="contained"
            onClick={confirmed}
          >
            Yes
          </LoadingButton>
        </>
      }
    />
  );
};

export default CustomConfirmation;

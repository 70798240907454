import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";

const MuiTable = ({
  isLoading,
  enableRowActions: enableRowActionFromProps,
  rowActionLabel = "",
  muiPaginationProps,
  onRowSelectionChange,
  ...props
}) => {

  const [enableRowActions, setEnableRowActions] = useState(
    enableRowActionFromProps || true
  );
  useEffect(() => {
    if (enableRowActionFromProps !== enableRowActions) {
      setEnableRowActions(enableRowActionFromProps);
    }
  }, [enableRowActionFromProps]);

  return (
    <MaterialReactTable
      enableTopToolbar={false}
      enableSorting
      enableColumnActions={false}
      density="compact"
      muiTableHeadCellProps={{
        sx: (theme) => ({
          borderRight: "1px solid rgba(224,224,224,1)",
          color: `${theme.palette.primary.main} !important`,
        }),
      }}
      muiPaginationProps={{
        rowsPerPageOptions: muiPaginationProps?.rowsPerPageOptions || [10],
        showFirstButton: false,
        showLastButton: false,
        showRowsPerPage: false,
        ...muiPaginationProps,
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - 308px)",
        },
      }}
      muiTableBodyRowProps={{
        sx: {
          height: "44px",
        },
      }}
      muiTableHeadRowProps={{
        sx: {
          height: "44px",
        },
      }}
      muiTableBodyCellProps={{
        style: {
          border: "1px solid #E9EAEC",
        },
      }}
      enableRowActions={enableRowActions}
      /*
       * enableRowActions is not mutable, have to trace this  issue
       * https://github.com/KevinVandy/material-react-table/issues/832
       * below `columnVisibility` is used to handle it.
       */
      state={{
        isLoading: isLoading,
        columnVisibility: {
          "mrt-row-actions": enableRowActions,
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          header: rowActionLabel,
        },
      }}
      onRowSelectionChange={onRowSelectionChange}
      {...props}
    />
  );
};

export default MuiTable;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const GenDialog = ({ title, content, open, actions }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      {actions !== undefined && (
        <DialogActions style={{ padding: "12px 24px" }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default GenDialog;
